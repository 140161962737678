exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acid-oil-plant-manufacturers-js": () => import("./../../../src/pages/acid-oil-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-acid-oil-plant-manufacturers-js" */),
  "component---src-pages-bakery-shortening-manufacturers-js": () => import("./../../../src/pages/bakery-shortening-manufacturers.js" /* webpackChunkName: "component---src-pages-bakery-shortening-manufacturers-js" */),
  "component---src-pages-belt-conveyors-manufacturers-js": () => import("./../../../src/pages/belt-conveyors-manufacturers.js" /* webpackChunkName: "component---src-pages-belt-conveyors-manufacturers-js" */),
  "component---src-pages-bio-diesel-plant-manufacturers-js": () => import("./../../../src/pages/bio-diesel-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-bio-diesel-plant-manufacturers-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bucket-elevators-manufacturers-js": () => import("./../../../src/pages/bucket-elevators-manufacturers.js" /* webpackChunkName: "component---src-pages-bucket-elevators-manufacturers-js" */),
  "component---src-pages-bulk-flow-conveyors-manufacturers-js": () => import("./../../../src/pages/bulk-flow-conveyors-manufacturers.js" /* webpackChunkName: "component---src-pages-bulk-flow-conveyors-manufacturers-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-castor-oil-derivatives-plant-manufacturers-js": () => import("./../../../src/pages/castor-oil-derivatives-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-castor-oil-derivatives-plant-manufacturers-js" */),
  "component---src-pages-cement-grinding-plant-manufacturers-js": () => import("./../../../src/pages/cement-grinding-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-cement-grinding-plant-manufacturers-js" */),
  "component---src-pages-cement-plant-manufacturers-js": () => import("./../../../src/pages/cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-cement-plant-manufacturers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cotton-seed-oil-mill-plant-manufacturers-js": () => import("./../../../src/pages/cotton-seed-oil-mill-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-cotton-seed-oil-mill-plant-manufacturers-js" */),
  "component---src-pages-demineralization-plant-manufacturers-js": () => import("./../../../src/pages/demineralization-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-demineralization-plant-manufacturers-js" */),
  "component---src-pages-design-and-production-engineer-2-years-js": () => import("./../../../src/pages/design-and-production-engineer-2-years.js" /* webpackChunkName: "component---src-pages-design-and-production-engineer-2-years-js" */),
  "component---src-pages-design-and-production-engineer-js": () => import("./../../../src/pages/design-and-production-engineer.js" /* webpackChunkName: "component---src-pages-design-and-production-engineer-js" */),
  "component---src-pages-dry-fractionation-plant-manufacturers-js": () => import("./../../../src/pages/dry-fractionation-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-dry-fractionation-plant-manufacturers-js" */),
  "component---src-pages-drying-lecithin-plant-manufacturers-js": () => import("./../../../src/pages/drying-lecithin-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-drying-lecithin-plant-manufacturers-js" */),
  "component---src-pages-earthing-installation-and-accessories-manufacturers-js": () => import("./../../../src/pages/earthing-installation-and-accessories-manufacturers.js" /* webpackChunkName: "component---src-pages-earthing-installation-and-accessories-manufacturers-js" */),
  "component---src-pages-edible-oil-plant-manufacturers-in-nigeria-js": () => import("./../../../src/pages/edible-oil-plant-manufacturers-in-nigeria.js" /* webpackChunkName: "component---src-pages-edible-oil-plant-manufacturers-in-nigeria-js" */),
  "component---src-pages-edible-oil-refinery-manufacturers-js": () => import("./../../../src/pages/edible-oil-refinery-manufacturers.js" /* webpackChunkName: "component---src-pages-edible-oil-refinery-manufacturers-js" */),
  "component---src-pages-effluent-treatment-plant-manufacturers-js": () => import("./../../../src/pages/effluent-treatment-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-effluent-treatment-plant-manufacturers-js" */),
  "component---src-pages-epc-solutions-js": () => import("./../../../src/pages/epc-solutions.js" /* webpackChunkName: "component---src-pages-epc-solutions-js" */),
  "component---src-pages-fat-splitting-plant-manufacturers-js": () => import("./../../../src/pages/fat-splitting-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-fat-splitting-plant-manufacturers-js" */),
  "component---src-pages-fatty-distillation-plant-manufacturers-js": () => import("./../../../src/pages/fatty-distillation-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-fatty-distillation-plant-manufacturers-js" */),
  "component---src-pages-filling-packing-unit-manufacturers-js": () => import("./../../../src/pages/filling-packing-unit-manufacturers.js" /* webpackChunkName: "component---src-pages-filling-packing-unit-manufacturers-js" */),
  "component---src-pages-fire-fighting-equipments-manufacturers-js": () => import("./../../../src/pages/fire-fighting-equipments-manufacturers.js" /* webpackChunkName: "component---src-pages-fire-fighting-equipments-manufacturers-js" */),
  "component---src-pages-fly-ash-drying-machine-manufacturers-js": () => import("./../../../src/pages/fly-ash-drying-machine-manufacturers.js" /* webpackChunkName: "component---src-pages-fly-ash-drying-machine-manufacturers-js" */),
  "component---src-pages-glycerine-distillation-plant-manufacturers-js": () => import("./../../../src/pages/glycerine-distillation-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-glycerine-distillation-plant-manufacturers-js" */),
  "component---src-pages-grain-storage-silos-manufacturers-js": () => import("./../../../src/pages/grain-storage-silos-manufacturers.js" /* webpackChunkName: "component---src-pages-grain-storage-silos-manufacturers-js" */),
  "component---src-pages-groundnut-oil-mill-plant-manufacturers-js": () => import("./../../../src/pages/groundnut-oil-mill-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-groundnut-oil-mill-plant-manufacturers-js" */),
  "component---src-pages-high-angle-conveyors-manufacturers-js": () => import("./../../../src/pages/high-angle-conveyors-manufacturers.js" /* webpackChunkName: "component---src-pages-high-angle-conveyors-manufacturers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-agitators-manufacturers-js": () => import("./../../../src/pages/industrial-agitators-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-agitators-manufacturers-js" */),
  "component---src-pages-industrial-boiler-suppliers-js": () => import("./../../../src/pages/industrial-boiler-suppliers.js" /* webpackChunkName: "component---src-pages-industrial-boiler-suppliers-js" */),
  "component---src-pages-industrial-conveyor-systems-manufacturers-js": () => import("./../../../src/pages/industrial-conveyor-systems-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-conveyor-systems-manufacturers-js" */),
  "component---src-pages-industrial-ro-plant-manufacturers-js": () => import("./../../../src/pages/industrial-ro-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-ro-plant-manufacturers-js" */),
  "component---src-pages-industrial-spare-parts-manufacturers-js": () => import("./../../../src/pages/industrial-spare-parts-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-spare-parts-manufacturers-js" */),
  "component---src-pages-industrial-warehouse-manufacturers-js": () => import("./../../../src/pages/industrial-warehouse-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-warehouse-manufacturers-js" */),
  "component---src-pages-industrial-water-treatment-plant-manufacturers-js": () => import("./../../../src/pages/industrial-water-treatment-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-industrial-water-treatment-plant-manufacturers-js" */),
  "component---src-pages-inter-esterification-plant-manufacturers-js": () => import("./../../../src/pages/inter-esterification-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-inter-esterification-plant-manufacturers-js" */),
  "component---src-pages-margarine-plant-manufacturers-js": () => import("./../../../src/pages/margarine-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-margarine-plant-manufacturers-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-laundry-soap-from-oil-byproducts-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/benefits-laundry-soap-from-oil-byproducts/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-laundry-soap-from-oil-byproducts-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-of-edible-oil-plant-setup-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/benefits-of-edible-oil-plant-setup/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-of-edible-oil-plant-setup-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-of-prefabricated-warehouse-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/benefits-of-prefabricated-warehouse/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-benefits-of-prefabricated-warehouse-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-biodiesel-production-influences-future-of-energy-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/biodiesel-production-influences-future-of-energy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-biodiesel-production-influences-future-of-energy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-chemical-and-physical-edible-oil-refining-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/chemical-and-physical-edible-oil-refining/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-chemical-and-physical-edible-oil-refining-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-cement-plant-manufacturing-company-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/choose-cement-plant-manufacturing-company/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-cement-plant-manufacturing-company-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-right-oil-seed-crushing-machine-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/choose-right-oil-seed-crushing-machine/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-right-oil-seed-crushing-machine-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-storage-tank-edible-oil-plants-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/choose-storage-tank-edible-oil-plants/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-storage-tank-edible-oil-plants-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-turnkey-contractor-edible-oil-mill-projects-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/choose-turnkey-contractor-edible-oil-mill-projects/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-choose-turnkey-contractor-edible-oil-mill-projects-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-cleaning-oilseeds-before-pressing-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/cleaning-oilseeds-before-pressing/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-cleaning-oilseeds-before-pressing-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-cost-to-start-a-mini-cement-plant-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/cost-to-start-a-mini-cement-plant/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-cost-to-start-a-mini-cement-plant-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-different-types-of-castor-oil-derivatives-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/different-types-of-castor-oil-derivatives/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-different-types-of-castor-oil-derivatives-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-business-in-south-africa-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/edible-oil-business-in-south-africa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-business-in-south-africa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-extraction-methods-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/edible-oil-extraction-methods/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-extraction-methods-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-refining-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/edible-oil-refining-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-edible-oil-refining-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-effluent-treatment-plants-transform-wastewater-management-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/effluent-treatment-plants-transform-wastewater-management/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-effluent-treatment-plants-transform-wastewater-management-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-exploring-the-global-impact-in-edible-oil-market-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/exploring-the-global-impact-in-edible-oil-market/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-exploring-the-global-impact-in-edible-oil-market-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-find-best-edible-oil-extraction-machine-manufacturers-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/find-best-edible-oil-extraction-machine-manufacturers/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-find-best-edible-oil-extraction-machine-manufacturers-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-future-trends-edible-oil-industry-africa-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/future-trends-edible-oil-industry-africa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-future-trends-edible-oil-industry-africa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-grain-storage-silos-for-storing-seeds-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/grain-storage-silos-for-storing-seeds/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-grain-storage-silos-for-storing-seeds-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-groundnut-oil-production-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/groundnut-oil-production-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-groundnut-oil-production-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-guide-to-efficient-biodiesel-production-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/guide-to-efficient-biodiesel-production/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-guide-to-efficient-biodiesel-production-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-cement-is-made-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/how-cement-is-made/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-cement-is-made-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-buy-quality-industrial-spare-parts-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/how-to-buy-quality-industrial-spare-parts/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-buy-quality-industrial-spare-parts-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-choose-the-right-edible-oil-plant-manufacturer-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/how-to-choose-the-right-edible-oil-plant-manufacturer/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-choose-the-right-edible-oil-plant-manufacturer-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-set-up-soap-manufacturing-plant-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/how-to-set-up-soap-manufacturing-plant/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-how-to-set-up-soap-manufacturing-plant-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-importance-of-industrial-spares-in-edible-oil-plant-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/importance-of-industrial-spares-in-edible-oil-plant/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-importance-of-industrial-spares-in-edible-oil-plant-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-palm-oil-production-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/palm-oil-production-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-palm-oil-production-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-powdered-soya-lecithin-production-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/powdered-soya-lecithin-production-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-powdered-soya-lecithin-production-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-process-of-making-mustard-oil-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/process-of-making-mustard-oil/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-process-of-making-mustard-oil-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-reasons-for-soybean-oil-popularity-as-edible-oil-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/reasons-for-soybean-oil-popularity-as-edible-oil/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-reasons-for-soybean-oil-popularity-as-edible-oil-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-rice-bran-oil-production-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/rice-bran-oil-production-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-rice-bran-oil-production-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-safety-and-modern-technology-for-edible-oil-plant-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/safety-and-modern-technology-for-edible-oil-plant/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-safety-and-modern-technology-for-edible-oil-plant-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-seed-crushing-machines-benefits-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/seed-crushing-machines-benefits/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-seed-crushing-machines-benefits-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-small-edible-oil-manufacturing-business-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/small-edible-oil-manufacturing-business/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-small-edible-oil-manufacturing-business-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-soybean-oil-extraction-process-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/soybean-oil-extraction-process/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-soybean-oil-extraction-process-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-soybean-oil-production-business-nigeria-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/soybean-oil-production-business-nigeria/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-soybean-oil-production-business-nigeria-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-spare-parts-supplier-for-edible-oil-factory-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/spare-parts-supplier-for-edible-oil-factory/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-spare-parts-supplier-for-edible-oil-factory-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-groundnut-oil-production-business-in-nigeria-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/start-groundnut-oil-production-business-in-nigeria/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-groundnut-oil-production-business-in-nigeria-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-mustard-oil-production-business-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/start-mustard-oil-production-business/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-mustard-oil-production-business-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-palm-oil-production-business-guide-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/start-palm-oil-production-business-guide/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-palm-oil-production-business-guide-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-rice-bran-oil-business-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/start-rice-bran-oil-business/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-rice-bran-oil-business-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-sunflower-oil-production-business-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/start-sunflower-oil-production-business/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-start-sunflower-oil-production-business-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-successfully-installs-200-tpd-solvent-extraction-plant-tanzania-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/successfully-installs-200-tpd-solvent-extraction-plant-tanzania/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-successfully-installs-200-tpd-solvent-extraction-plant-tanzania-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-sunflower-oil-production-business-in-russia-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/sunflower-oil-production-business-in-russia/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-sunflower-oil-production-business-in-russia-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-top-conveyor-system-manufacturers-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/top-conveyor-system-manufacturers/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-top-conveyor-system-manufacturers-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-types-of-industial-conveyor-systems-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/types-of-industial-conveyor-systems/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-types-of-industial-conveyor-systems-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-types-of-warehouses-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/types-of-warehouses/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-types-of-warehouses-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-understanding-industrial-ro-plants-how-they-work-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/understanding-industrial-ro-plants-how-they-work/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-understanding-industrial-ro-plants-how-they-work-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-why-businesses-need-prefabricated-warehouses-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/why-businesses-need-prefabricated-warehouses/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-why-businesses-need-prefabricated-warehouses-index-mdx" */),
  "component---src-pages-mustard-oil-mill-plant-manufacturers-js": () => import("./../../../src/pages/mustard-oil-mill-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-mustard-oil-mill-plant-manufacturers-js" */),
  "component---src-pages-oilseeds-oil-fats-technologies-js": () => import("./../../../src/pages/oilseeds-oil-fats-technologies.js" /* webpackChunkName: "component---src-pages-oilseeds-oil-fats-technologies-js" */),
  "component---src-pages-osbl-piping-cabling-manufacturers-js": () => import("./../../../src/pages/osbl-piping-cabling-manufacturers.js" /* webpackChunkName: "component---src-pages-osbl-piping-cabling-manufacturers-js" */),
  "component---src-pages-piping-racks-manufacturers-js": () => import("./../../../src/pages/piping-racks-manufacturers.js" /* webpackChunkName: "component---src-pages-piping-racks-manufacturers-js" */),
  "component---src-pages-powder-lecithin-plant-manufacturers-js": () => import("./../../../src/pages/powder-lecithin-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-powder-lecithin-plant-manufacturers-js" */),
  "component---src-pages-rotary-cement-plant-manufacturers-js": () => import("./../../../src/pages/rotary-cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-rotary-cement-plant-manufacturers-js" */),
  "component---src-pages-rotary-dryer-plant-manufacturers-js": () => import("./../../../src/pages/rotary-dryer-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-rotary-dryer-plant-manufacturers-js" */),
  "component---src-pages-sales-marketing-specialist-js": () => import("./../../../src/pages/sales-marketing-specialist.js" /* webpackChunkName: "component---src-pages-sales-marketing-specialist-js" */),
  "component---src-pages-screw-conveyor-manufacturers-js": () => import("./../../../src/pages/screw-conveyor-manufacturers.js" /* webpackChunkName: "component---src-pages-screw-conveyor-manufacturers-js" */),
  "component---src-pages-seed-crushing-plant-manufacturers-js": () => import("./../../../src/pages/seed-crushing-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-seed-crushing-plant-manufacturers-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sesame-seed-oil-mill-plant-manufacturers-js": () => import("./../../../src/pages/sesame-seed-oil-mill-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-sesame-seed-oil-mill-plant-manufacturers-js" */),
  "component---src-pages-site-services-js": () => import("./../../../src/pages/site-services.js" /* webpackChunkName: "component---src-pages-site-services-js" */),
  "component---src-pages-slag-grinding-plant-manufacturers-js": () => import("./../../../src/pages/slag-grinding-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-slag-grinding-plant-manufacturers-js" */),
  "component---src-pages-soap-making-plant-manufacturers-js": () => import("./../../../src/pages/soap-making-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-soap-making-plant-manufacturers-js" */),
  "component---src-pages-solvent-extraction-plant-manufacturers-js": () => import("./../../../src/pages/solvent-extraction-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-solvent-extraction-plant-manufacturers-js" */),
  "component---src-pages-soybean-oil-plant-manufacturer-js": () => import("./../../../src/pages/soybean-oil-plant-manufacturer.js" /* webpackChunkName: "component---src-pages-soybean-oil-plant-manufacturer-js" */),
  "component---src-pages-spent-earth-extraction-plant-manufacturers-js": () => import("./../../../src/pages/spent-earth-extraction-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-spent-earth-extraction-plant-manufacturers-js" */),
  "component---src-pages-steel-structure-manufacturers-js": () => import("./../../../src/pages/steel-structure-manufacturers.js" /* webpackChunkName: "component---src-pages-steel-structure-manufacturers-js" */),
  "component---src-pages-storage-tanks-manufacturers-js": () => import("./../../../src/pages/storage-tanks-manufacturers.js" /* webpackChunkName: "component---src-pages-storage-tanks-manufacturers-js" */),
  "component---src-pages-sunflower-oil-mill-plant-manufacturers-js": () => import("./../../../src/pages/sunflower-oil-mill-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-sunflower-oil-mill-plant-manufacturers-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-ultra-fine-grinding-plant-js": () => import("./../../../src/pages/ultra-fine-grinding-plant.js" /* webpackChunkName: "component---src-pages-ultra-fine-grinding-plant-js" */),
  "component---src-pages-vsk-cement-plant-manufacturers-js": () => import("./../../../src/pages/vsk-cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-vsk-cement-plant-manufacturers-js" */),
  "component---src-pages-workshop-services-js": () => import("./../../../src/pages/workshop-services.js" /* webpackChunkName: "component---src-pages-workshop-services-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

